import React, { useState } from "react";
import { Accordion, AccordionBox, AccordionButtonTitle, ModelIcon, ModelText, ModelName } from "../../AccordionUI";
import { CalendarIcon, DetailIcon, FactorIcon, NewsIcon, OpenArrowDownIcon, TwitterIcon } from "../../../Icons/Icons";
import { BlankSpacer, Line } from "../../../StyledComponents/AppStyledComponents";
import { formatDateTime } from "../../../../Controllers/utils";

const categoryOrder = [
    'news_social',
    'flow_volume',
    'events',
    'cross_asset',
  ];

const ModelSummaryAccordion = ({ modelData }) => {
    const [categoryOpen, setCategoryOpen] = useState({
        news_social: true,
        flow_volume: true,
        events: true,
        cross_asset: true,
    });

    const handleToggleAccordion = (category) => {
        setCategoryOpen({
            ...categoryOpen,
            [category]: !categoryOpen[category],
        });

    };

    const filteredAndSorted = modelData?.filter(model => model.summary !== null)
        .sort((a, b) => categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category))

    return (
        <>
            {modelData ? (
                <Accordion>
                    {filteredAndSorted
                        .map((model, index) => {
                            const key = model.symbol + model.mdl_name;
                            let accordionTitle;
                            let modelTagIcon;
                            const { category } = model;

                            switch (category) {
                                case 'flow_volume':
                                    accordionTitle = 'Flow & Volume';
                                    modelTagIcon = <DetailIcon />;
                                    break;
                                case 'cross_asset':
                                    accordionTitle = 'Cross-Asset Drivers';
                                    modelTagIcon = <FactorIcon />;
                                    break;
                                case 'news_social':
                                    accordionTitle = 'News & Social Media';
                                    modelTagIcon = <NewsIcon />;
                                    if (model.short_tag === 'social') {
                                        modelTagIcon = <TwitterIcon />;
                                    }
                                    break;
                                case 'events':
                                    accordionTitle = 'Events';
                                    modelTagIcon = <CalendarIcon />;
                                    break;
                                default:
                                    accordionTitle = model.tag;
                            }

                            if (key !== 'NaN' && model.summary != null) {
                                if (category === filteredAndSorted[index - 1]?.category) {
                                    return (
                                        <AccordionBox isOpen={categoryOpen[category]}>
                                            <ModelIcon>{modelTagIcon}</ModelIcon>
                                            <ModelName>{model.name}</ModelName>
                                            <div>
                                                <ModelText>
                                                    {model.summary.split('\n\n').map(summary => (
                                                        <>
                                                            <p>{summary}</p>
                                                            <br />
                                                        </>
                                                    ))}
                                                    <p>Published: {formatDateTime(model.time_utc)}</p>
                                                </ModelText>
                                            </div>
                                        </AccordionBox>
                                    );
                                }
                                return (
                                    <>
                                        <AccordionButtonTitle onClick={() => handleToggleAccordion(category)} isOpen={categoryOpen[category]}>
                                            {accordionTitle}
                                            <OpenArrowDownIcon isOpen={categoryOpen[category]}/>
                                        </AccordionButtonTitle>
                                        <AccordionBox isOpen={categoryOpen[category]}>
                                            <ModelIcon>{modelTagIcon}</ModelIcon>
                                            <ModelName>{model.name}</ModelName>
                                            <div>
                                                <ModelText>
                                                    {model.summary.split('\n\n').map(summary => (
                                                        <>
                                                            <p>{summary}</p>
                                                            <br />
                                                        </>
                                                    ))}
                                                    <p>Published: {formatDateTime(model.time_utc)}</p>
                                                </ModelText>
                                            </div>
                                        </AccordionBox>
                                       
                                    </>
                                );
                            }
                            return null;
                        })}
                         <Line />
                        <BlankSpacer />
                </Accordion>
            ) : null}
        </>
    );
};

export default ModelSummaryAccordion;