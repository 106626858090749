export const CFDs = [
    'index_cfd',
    'commodity',
    'currency_digital',
    'currency_fiat',
];

export const DefaultSort = {
    asc: false,
    abs: true,
    key: 'date_return_oc_voladj',
    correlationAsset: 'SPY',
};

export const Plans = {
    monthly: {
        individual: {
            title: 'Individual',
            description: "Know what's moving, Understand why. No more scanning through noisy news/social feeds. Only $3 per day to help your trading exponentially over the next month. Cancel any time during your subscription.",
            price: '$99',
            interval: 'per month',
            priceModel: 'indMonthly',
            buttonText: {
                trial: 'Start Trial',
                subscription: 'Start Subscription',
            },
            includes: [
                'Live view of most unusual moves of the day',
                'Real-time AI-generated asset price movement summaries (stocks and ETFs)',
                'Industry Peers and Correlated Assets filters for every asset',
                'Customizable watchlists',
                'Intraday charting',
                'Smart Screener with customizable and savable filters',
                'Email support',
            ],
        },
        pro: {
            title: 'Enterprise',
            description: 'Upgraded speed and functionality. Understand what is moving the market with real-time AI-generated explanations of asset price movement. Save time, cover more assets, manage risk, and trade smarter.',
            price: '$549',
            interval: 'per month',
            priceModel: 'proMonthly',
            buttonText: {
                trial: 'Start Trial',
                subscription: 'Start Subscription',
            },
            includes: [
                'Live view of most unusual moves of the day',
                'Real-time AI-generated asset price movement summaries (stocks and ETFs)',
                'Industry Peers and Correlated Assets filters for every asset',
                'Customizable watchlists',
                'Intraday charting',
                'Smart Screener with customizable and savable filters',
                'Email support',
                'ETF Monitor',
                'Earliest access to new features and upgrades',
            ],
        },
    },
    yearly: {
        individual: {
            title: 'Individual',
            description: "Know what's moving, Understand why. No more scanning through noisy news/social feeds. Only $3 per day to help your trading exponentially over the next month. Cancel any time during your subscription.",
            price: '$1099',
            interval: 'per year',
            priceModel: 'indYearly',
            buttonText: {
                trial: 'Start Trial',
                subscription: 'Start Subscription',
            },
            includes: [
                'Live view of most unusual moves of the day',
                'Real-time AI-generated asset price movement summaries (stocks and ETFs)',
                'Industry Peers and Correlated Assets filters for every asset',
                'Customizable watchlists',
                'Intraday charting',
                'Smart Screener with customizable and savable filters',
                'Email support',
            ],
        },
        pro: {
            title: 'Enterprise',
            description: 'Upgraded speed and functionality. Understand what is moving the market with real-time AI-generated explanations of asset price movement. Save time, cover more assets, manage risk, and trade smarter.',
            price: '$6,000',
            interval: 'per year',
            priceModel: 'proYearly',
            buttonText: {
                trial: 'Start Trial',
                subscription: 'Start Subscription',
            },
            includes: [
                'Live view of most unusual moves of the day',
                'Real-time AI-generated asset price movement summaries (stocks and ETFs)',
                'Industry Peers and Correlated Assets filters for every asset',
                'Customizable watchlists',
                'Intraday charting',
                'Smart Screener with customizable and savable filters',
                'Email support',
                'ETF Monitor',
                'Earliest access to new features and upgrades',
            ],
        },
    },
};

export const PriceModels = {
    individual: 'individual',
    proMonthly: 'proMonthly',
    proYearly: 'proYearly',
};

export const PromoCodes = {
    FREE30DAYS: 'Success! You have been upgraded to a 30 day free trial!',
    LABIS10: 'Success! You will receive 10% off your first year!',
    PRISTINECAP10: 'Success! You will receive 10% off your first year!',
};

export const PromoCodeCopy = {
    title: 'Get Free Early Access for 30 Days. Sign Up Below',
    description: 'Get access to the MarketReader user platform to understand WHAT is moving in the market and WHY. Covers all US-listed stocks and ETFs. Cancel any time.',
    list: [
        'AI-generated explanations of asset volatility',
        'Customizable watchlists',
        'Intraday charting',
        'Smart Screener with customizable and savable filters',
    ],
}

export const RegulatoryQuestions = {
    description: [
        "U.S. stock exchanges like NYSE, Nasdaq, and others require data vendors such as us to collect and maintain personal information and employment information for our data recipients.",
        "Per exchange policies, you are considered a Professional Subscriber until you are qualified as a Non-Professional Subscriber, and the exchanges reserve the right to request it at any time, along with additional details related to the subscriber's status, regardless of country or legal restrictions. It is important that you properly classify yourself in order to avoid any penalties or fines.",
        "Please keep in mind that you must contact us if your status changes, and you must re-verify your status as well.",
    ],
    header: 'Please answer YES/NO to the following:',
    questions: [
        'Do you use market data solely for your personal, non-business use?',
        'Do you receive market data for your business or any other entity?',
        'Are you currently registered or qualified with the SEC or the CFTC?',
        'Are you currently registered or qualified with any securities agency, any securities exchange, association or regulatory body, or any commodities or futures contract market, association or regulatory body, in the United States or elsewhere?',
        'Whether you are located within or outside of the United States, do you perform any functions that are similar to those that require an individual to register or qualify with the SEC, the CFTC, any other securities agency or regulatory body, any securities exchange or association, or any commodities or futures contract market, association or regulatory body?',
        'Are you engaged to provide investment advice to any individual or entity?',
        'Are you engaged as an asset manager?',
        'Do you use the capital of any other individual or entity in the conduct of your trading? (trading on margin is ok)',
        'Do you conduct trading for the benefit of a corporation, partnership, or other entity?',
        'Have you entered into any agreement to share the profit of your trading activities or receive compensation for your trading activities?',
        'Are you receiving office space, and equipment or other benefits in exchange for your trading or work as a financial consultant to any person, firm or business entity?',
    ],
    title: 'Subscriber Questions for Regulatory Reporting',
};

export const RefreshRates = {
    label: 'Set Automatic Refresh Rate',
    values: [
        {
            label: 'Off (Manual)',
            value: null,
        },
        {
            label: '1 minute',
            value: 60000,
        },
        {
            label: '5 minute',
            value: 300000,
        },
        {
            label: '10 minute ',
            value: 600000,
        },
    ],
}

export const SortOrder = {
    label: 'Sort Order',
    values: [
        {
            label: 'Ascending',
            value: JSON.stringify({ asc: true, abs: false }),
        },
        {
            label: 'Absolute Value Ascending',
            value: JSON.stringify({ asc: true, abs: true }),
        },
        {
            label: 'Absolute Value Descending',
            value: JSON.stringify({ asc: false, abs: true }),
        },
        {
            label: 'Descending',
            value: JSON.stringify({ asc: false, abs: false }),
        },
    ],
};

export const SortOrderStrings = {
    label: 'Sort Order',
    values: [
        {
            label: 'Ascending',
            value: JSON.stringify({ asc: true, abs: false }),
        },
        {
            label: 'Descending',
            value: JSON.stringify({ asc: false, abs: false }),
        },
    ],
};

export const SortOn = {
    label: 'Sort On',
    values: [
        {
            label: 'Volatility Adjusted Return (σ, Daily)',
            value: 'date_return_oc_voladj',
        },
        {
            label: 'Information Score',
            value: `COALESCE((dtm_summary->'dtm_summary_meta'->'information_score'->'raw')::numeric,0)`,
        },
        {
            label: 'Return (%, Daily)',
            value: 'date_return_oc',
        },
        // {
        //     label: 'Volatility Adjusted Volume (σ, Daily)',
        //     value: 'date_volume_voladj',
        // },
        
        // {
        //     label: 'DTM Volume (σ)',
        //     value: 'date_volume_dollar',
        // },
        // {
        //     label: 'Market Cap ($)',
        //     value: 'mcap_mm',
        // },
        {
            label: 'Market Cap Change ($, Daily)',
            value: 'date_return_oc*mcap_mm',
        },
        // {
        //     label: 'Sector',
        //     value: '"gsector||gind||gsubind"',
        // },
        // {
        //     label: '10min Return (%)',
        //     value: 'return_oc',
        // },
        // {
        //     label: 'Volatility Adjusted 10min Volume (σ)',
        //     value: 'volume_voladj',
        // },
        // {
        //     label: 'Volatility Adjusted 10min Return (σ)',
        //     value: 'return_oc_voladj',
        // },
        // {
        //     label: '10min Volume (σ)',
        //     value: 'volume_dollar',
        // },
        {
            label: 'Price ($)',
            value: 'close',
        },
        {
            label: 'Symbol',
            value: 'symbol',
        },
        {
            label: 'Correlation',
            value: 'correlation',
        },
        {
            label: 'Beta',
            value: 'beta',
        },
        {
            label: 'Volume',
            value: 'date_volume',
        },
        {
            label: 'Dollar Volume',
            value: 'date_volume_dollar',
        }
    ],
};

export const StringSortItems = [
    '"gsector||gind||gsubind"',
    'symbol',
    'date_volume_voladj',
    'volume_voladj',
    'date_volume_dollar',
    'date_volume',
    'volume_dollar',
    'mcap_mm',
];
