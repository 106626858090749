import { useContext, useState } from "react";

import { BlankSpacer } from "../../../Global/StyledComponents/AppStyledComponents";
import { WhiteTextGlassLogo } from "../../../Global/Logos/Logos";
import {
    MobileFormWrapper,
    FormWrapper,
    Header,
    Input,
    Form,
    SubmitButton,
    StyledButton,
    StyledLabel,
    StyledValue,
    CheckboxItem,
    CheckboxLabel,
} from "../../../Global/StyledComponents/UserFormStyledComponents";
import { AppColors } from "../../../Global/StyledComponents/Graphics";
import supabase from "../../../Controllers/utils/supabaseClient";
import { StateContext } from "../../../Controllers/context/state";
import { CopyIcon } from "../../../Global/Icons/Icons";
import { copyTextToClipboard } from "../../../Controllers/utils";
import Tooltip from "../../../Global/Components/Tooltip";
import CheckboxList from "../../../Global/Components/CheckboxList";


const EnterpriseApiUserManager = () => {
    const [allowApiTokenReplacement, setAllowApiTokenReplacement] = useState(false);
    const [apiAccountName, setApiAccountName] = useState('');
    const [apiAccountEmail, setApiAccountEmail] = useState('');
    const [apiAccountToken, setApiAccountToken] = useState('');
    const [apiAccountType, setApiAccountType] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [showApiAccountManager, setShowApiAccountManager] = useState({
        createNewApiAccount: false,
        lookupExistingApiAccount: false,
    });
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isCopied, setIsCopied] = useState(false);
    const {
        logError,
    } = useContext(StateContext);

    const handleAllowApiTokenReplacement = () => {
        setAllowApiTokenReplacement(prevState => !prevState);
    };

    const handleApiAccountNameChange = (e) => {
        setApiAccountName(e.target.value);
    };

    const handleApiAccountEmailChange = (e) => {
        setApiAccountEmail(e.target.value);
    };

    const handleApiAccountTypeChange = (id, value) => {
        setApiAccountType(() => {
            const newState = {};

            value.forEach(item => {
                newState[item.value] = true;
            });

            return newState;
        });
    };

    const handleCopyApiToken = () => {
        copyTextToClipboard(apiAccountToken).then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        });
    };

    const handleCreateApiAccount = async () => {
        setDisabled(true);
        const { data, error } = await supabase.functions.invoke('createApiUser', {
            body: {
                name: apiAccountName,
                email: apiAccountEmail,
                apiType: apiAccountType,
            }
        })

        if (error) {
            logError(error);
            return;
        }

        const { message, ok, apiData } = JSON.parse(data)

        if (ok) {
            setApiAccountToken(apiData?.[0]?.token);
            setError(false);
            setDisabled(false);
            return;
        }

        if (!ok) {
            setError(true);
            setErrorMessage(message);
            setDisabled(false);
            return;
        }
        setDisabled(false);
    };

    const handleLookupApiAccount = async () => {
        setDisabled(true);
        const { data, error } = await supabase.rpc('enterprise_get_token', {
            _email: apiAccountEmail,
        })
        if (error) {
            logError(error);
        }

        const { token } = data[0]

        if (token) {
            setApiAccountToken(token);
            setError(false);
            setDisabled(false);
            return;
        }
    };

    const handleReplaceApiToken = async () => {
        setDisabled(true);
        const { data, error } = await supabase.rpc('enterprise_replace_token', {
            _email: apiAccountEmail,
        })
        if (error) {
            logError(error);
        } else {
            setApiAccountToken(data);
        }

        const { token } = data[0];

        if (token) {
            setApiAccountToken(token);
            setError(false);
            setDisabled(false);
            return;
        }

    };

    const toggleApiAccountManager = (key) => () => {
        setShowApiAccountManager(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    const handleBack = () => {
        setApiAccountName('');
        setApiAccountEmail('');
        setApiAccountToken('');
        setApiAccountType('macro');
        setDisabled(false);
        setError(false);
        setErrorMessage('');
        setIsCopied(false);

        const activeKey = Object.keys(showApiAccountManager).find(key => showApiAccountManager[key]);
        if (activeKey) {
            setShowApiAccountManager(prevState => ({
                ...prevState,
                [activeKey]: false
            }));
        }
    };

    return (
        <MobileFormWrapper>
            <FormWrapper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                <WhiteTextGlassLogo title="Market Reader logo" />
                {Object.values(showApiAccountManager).some(value => value) &&
                    <StyledButton
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 10,
                            backgroundColor: AppColors.MRNAVY,
                        }}
                        onClick={handleBack}
                    >
                        Back
                    </StyledButton>
                }
                {!Object.values(showApiAccountManager).some(value => value) &&
                    <>
                        <Header>Enterprise API User Manager</Header>
                        <SubmitButton onClick={toggleApiAccountManager('createNewApiAccount')}>Create New API Account</SubmitButton>
                        <BlankSpacer />
                        <SubmitButton onClick={toggleApiAccountManager('lookupExistingApiAccount')}>Lookup Existing API Account</SubmitButton>
                    </>
                }
                {showApiAccountManager.createNewApiAccount &&
                    <>
                        {apiAccountToken ?
                            <>
                                <Header>
                                    API Account Created Successfully!
                                </Header>
                                <Header>
                                    API Account Email: {apiAccountEmail}
                                </Header>
                                <StyledLabel>API Account Token:</StyledLabel>
                                <StyledValue 
                                    style={{
                                        whiteSpace: 'wrap',
                                        wordWrap: 'break-word',
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: '14px',
                                        padding: '10px',
                                        backgroundColor: AppColors.MRNAVY,
                                        color: AppColors.MRWHITE,
                                        borderRadius: '5px',
                                    }}
                                >
                                    {apiAccountToken}
                                </StyledValue>
                                <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '5px' }} onClick={handleCopyApiToken}>
                                    <CopyIcon selected={isCopied}/>
                                    Copy API Token
                                </div>
                            </> :
                            <Form>
                                <Header>Create New API Account</Header>
                                <Input
                                    className='login'
                                    type="text"
                                    placeholder="API Account Name"
                                    onChange={handleApiAccountNameChange}
                                    value={apiAccountName}
                                />
                                <Input
                                    className='login'
                                    type="text"
                                    placeholder="API Account Email"
                                    onChange={handleApiAccountEmailChange}
                                    value={apiAccountEmail}
                                />
                                <CheckboxList
                                    label='API Account Type'
                                    handleChange={handleApiAccountTypeChange}
                                    options={[
                                        {
                                            label: 'Macro API',
                                            value: 'macro',
                                        }, {
                                            label: 'Equity API',
                                            value: 'equity',
                                        }
                                    ]}
                                />
                                <BlankSpacer />
                                <BlankSpacer />
                                <SubmitButton
                                    onClick={handleCreateApiAccount}
                                    disabled={disabled || !apiAccountName || !apiAccountEmail || !Object.values(apiAccountType).some(value => value)}
                                >
                                    Create API Account
                                </SubmitButton>
                                {error &&
                                    <>
                                        <WarningText>
                                            {errorMessage}
                                        </WarningText>
                                        <br />
                                    </>
                                }
                            </Form>
                        }
                    </>
                }
                {showApiAccountManager.lookupExistingApiAccount &&
                    <>
                        {apiAccountToken ?
                            <Form>
                                <Header>
                                    API Token for {apiAccountEmail}
                                </Header>
                                <StyledLabel>API Account Token:</StyledLabel>
                                <StyledValue 
                                    style={{
                                        whiteSpace: 'wrap',
                                        wordWrap: 'break-word',
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: '14px',
                                        padding: '10px',
                                        backgroundColor: AppColors.MRNAVY,
                                        color: AppColors.MRWHITE,
                                        borderRadius: '5px',
                                    }}
                                >
                                    {apiAccountToken}
                                </StyledValue>
                                <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '5px' }} onClick={handleCopyApiToken}>
                                    <CopyIcon selected={isCopied}/>
                                    Copy API Token
                                </div>
                                <BlankSpacer />
                                <BlankSpacer />
                                <Tooltip
                                    position='bottom'
                                    text='This will replace the existing token with a new one, which will discontinue the clients ability to connect to the API with their curront token.'
                                    tooltipWidth={400}
                                    width={250}
                                >
                                    <CheckboxItem
                                        checked={allowApiTokenReplacement}
                                        onClick={handleAllowApiTokenReplacement}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={allowApiTokenReplacement}
                                            readOnly
                                        />
                                        <CheckboxLabel>Allow API Token Replacement</CheckboxLabel>
                                    </CheckboxItem>
                                </Tooltip>
                                <BlankSpacer />
                                <BlankSpacer />
                                <SubmitButton
                                    onClick={handleReplaceApiToken}
                                    disabled={disabled || !allowApiTokenReplacement}
                                >
                                    Replace API Token
                                </SubmitButton>
                            </Form> :
                            <Form>
                                <Header>Lookup Existing API Account</Header>
                                <Input
                                    className='login'
                                    type="text"
                                    placeholder="API Account Email"
                                    onChange={handleApiAccountEmailChange}
                                    value={apiAccountEmail}
                                />
                                <SubmitButton
                                    onClick={handleLookupApiAccount}
                                    disabled={disabled}
                                >
                                    Lookup API Account
                                </SubmitButton>
                            </Form>
                        }
                    </>
                }
            </FormWrapper>
        </MobileFormWrapper>
    )
}

export default EnterpriseApiUserManager;
