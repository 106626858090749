import { useEffect, useState, useContext, useRef } from 'react';
import SearchDropdown from '../SearchDropdown';
import { StateContext } from '../../../Controllers/context/state';
import supabase from '../../../Controllers/utils/supabaseClient';
import StockChartToggle from './components/StockChartToggle/StockChartToggle';
import { PercentileMoves, ImpactScore } from './constants';
import { Container, Items, TickerInfo, StockChartContainer } from './styled-components';
import Loading from '../LoadingSpinner';
import { fetchBars, fetchMarkers, fetchLiveOhlcvDailyData } from './components/StockChart/support';
import StockChart from './components/StockChart/StockChart';
import StockChartCarousel from './components/StockChartCarousel/StockChartCarousel';
import DropdownMenu from '../DropdownMenu';
import { capitalizeFirstLetter } from '../../../Controllers/utils';
import { Input } from '../../StyledComponents/UserFormStyledComponents';



const StockChartWrapper = ({ assetPage, assets,dateDisplay, handleDateChange, handleNavigation, isDelisted,lastDate, setLastDate, symbol, width }) => {
	const [carouselPlotLine, setCarouselPlotline] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [chartMinMax, setChartMinMax] = useState({ min: 0, max: 0 });
    const { chartType, setChartType,setChartTimeStamp, development, sort } = useContext(StateContext);
    const [clickedPoint, setClickedPoint] = useState(null);
    const [hoveredPoint, setHoverPoint] = useState(null);
    const [loading, setLoading] = useState(true);
    const [markerData, setMarkerData] = useState(null);
    // const [selectedImpactScore, setSelectedImpactScore] = useState(ImpactScore.values[0].value);
    const [selectedPercentile, setSelectedPercentile] = useState(PercentileMoves.values[3].value);
    const stockChartWrapperRef = useRef(null);
    const [tickerData, setTickerData] = useState({});

	const handleChangePercentile = (e) => {
		setSelectedPercentile(e.target.value);
	};

    const formatSectorAndType = (gsector, type) => {
        const formattedGsector = capitalizeFirstLetter(gsector);
    
        let formattedType = type;
        if (type !== "ETF" && type !== "ETP") {
            formattedType = capitalizeFirstLetter(type);
        }
    
        if (formattedGsector && formattedType) {
            return `${formattedGsector}, ${formattedType}`;
        } else {
            return formattedGsector || formattedType || '';
        }
    }
	// const handleChangeImpactScore = (e) => {
	// 	setSelectedImpactScore(e.target.value);
	// };

	// const lowerSelectedValues = () => {
	// 	const newImpactIndex = ImpactScore.values.findIndex((item) => item.value === selectedImpactScore) + 1;
	// 	const newSelectedPercentileIndex = PercentileMoves.values.findIndex((item) => item.value === selectedPercentile) + 1;

    //     if (newImpactIndex < ImpactScore.values.length) {
	// 		setSelectedImpactScore(ImpactScore.values[newImpactIndex].value);
    //         return;
	// 	}        
    //     if (newSelectedPercentileIndex < PercentileMoves.values.length) {
	// 		setSelectedPercentile(PercentileMoves.values[newSelectedPercentileIndex].value);
	// 	}
	// };

    const fetchTickerInfo = async () => {
        // const res = await supabase.rpc('card_data', {
        //     _symbol: symbol,
        //     _corr_symbol: sort.correlationAsset || 'SPY',
        // });
        // console.log(res);
        // if (res?.error) {
        //     const error = new Error(res?.error?.message);
        //     logError(error.message, undefined, error);

        //     if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
        //         fetchData({ retry: false });

        //         return;
        //     }

        //     if (res?.error?.message !== 'FetchError: Failed to fetch') {
        //         setShowErrorWindow(true);
        //     }

        //     return;
        // }

        // if (res?.data?.length) {
        //     const { name, type, gsector, gsubind } = res.data[0];
        //     setTickerData({ name, type, gsector, gsubind });
        // }
        // else{
        //     setTickerData({ name: 'Ticker not found', type: 'N/A', gsector: 'N/A', gsubind: 'N/A' });
        // }
        const res = await supabase.from('asset_symbols_view').select('*').eq('symbol', symbol);
        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            return;
        }
        if (res?.data?.length) {
            const { name, type, gsector, gsubind } = res.data[0];
            setTickerData({ name, type, gsector, gsubind });
        }
    };

    const fetchDailyData = async () => {
        setLoading(true);

        const date = new Date();
        date.setDate(date.getDate() + 1);
        const countBack = 280;

		const dailyData = await fetchBars({
			count: countBack,
			endDate: Math.ceil(date.getTime() / 1000),
			endPoint: 'ohlcv_1day_v2',
			symbol: symbol,
		});
        setChartData({ data: dailyData, navigator: 'D' });
        setLoading(false);
    };

    const fetchLiveDailyData = async () => {
        const liveData = await fetchLiveOhlcvDailyData({ symbol, retry: true });
    
        if (!liveData || !chartData || !chartData.data || chartData.data.length === 0) return;
        const lastChartDataPoint = chartData.data[chartData.data.length - 1];
        const lastLiveDataPoint = liveData[0];
        if (lastChartDataPoint.time_utc === lastLiveDataPoint.time_utc &&
            (lastChartDataPoint.open !== lastLiveDataPoint.open ||
             lastChartDataPoint.high !== lastLiveDataPoint.high ||
             lastChartDataPoint.low !== lastLiveDataPoint.low ||
             lastChartDataPoint.close !== lastLiveDataPoint.close ||
             lastChartDataPoint.volume !== lastLiveDataPoint.volume)) {
            
            const updatedChartData = [...chartData.data];
            updatedChartData[updatedChartData.length - 1] = lastLiveDataPoint;

            setChartData(prevState => ({
                ...prevState,
                data: updatedChartData
            }));
        
        }

        if(lastChartDataPoint.time_utc !== lastLiveDataPoint.time_utc){
            const updatedChartData = [...chartData.data, lastLiveDataPoint];
            setChartData(prevState => ({
                ...prevState,
                data: updatedChartData
            }));
        }
    }

    const fetchIntraData = async (endDate = null) => {
        setLoading(true);
        const countBack = 300;
        
        const endDateToUse = endDate 
            ? Math.ceil(endDate) 
            : Math.ceil(new Date().getTime() / 1000);

        const intraData = await fetchBars({
            count: countBack,
            endDate: endDateToUse,
            endPoint: 'ohlcv_10min_v2',
            symbol: symbol,
        });

        setChartData({ data: intraData, navigator: '10m' });
        setLoading(false);
    };

    const fetchDailyMarkers = async () => {
        const currentDate = new Date();
		currentDate.setMonth(currentDate.getMonth() - 11);
		const epochThreeMonthsAgo = Math.ceil(currentDate.getTime() / 1000);
        const flagData = await fetchMarkers({
			aggregate: 'dtm',
			endPoint: 'get_marker_timestamps',
			epoch: epochThreeMonthsAgo,
			retry: true,
            return_percentile_floor: selectedPercentile,
			symbol: symbol,
		});


        if(isDelisted){
            const lastMarker = flagData[flagData.length - 1].ts / 1000;
            setLastDate(lastMarker);
            setChartTimeStamp({aggr: 'D', timestamp: lastMarker});
        }
        setMarkerData(flagData);
    }

    const fetchIntraMarkers = async (epoch = null) => {
        let epochToUse;
        
        if (epoch === null) {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 7);
            epochToUse = Math.ceil(currentDate.getTime() / 1000);
        } else {
            epochToUse = epoch;
        }

        const flagData = await fetchMarkers({
            aggregate: '10min',
            endPoint: 'get_marker_timestamps',
            epoch: epochToUse,
            retry: true,
            return_percentile_floor: selectedPercentile,
            symbol: symbol,
        });

        setMarkerData(flagData);
    }

    const loadMoreData = async (min) => {
        const countBack = 60;
        const dailyData = await fetchBars({
            count: countBack,
            endDate: min / 1000,
            endPoint: 'ohlcv_1day_v2',
            symbol: symbol,
        });

        return dailyData;
    }

    const fetchData = async () => {
        await fetchTickerInfo();
        if (chartType === 'D') {
            await fetchDailyData();
        } else {
            await fetchIntraData();
        }
    };

    const fetchMarkersData = async () => {
        if (chartType === 'D') {
            await fetchDailyMarkers();
        } else {
            await fetchIntraMarkers();
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchIntraData(lastDate);
            await fetchIntraMarkers(lastDate);
        }
        if(lastDate && isDelisted && chartType === '10m'){
            fetchData();
        }
    }, [lastDate]);

    useEffect(() => {
        const fetchLastDataDate = async () => {
            await fetchDailyMarkers();
        }
        if(isDelisted){
            fetchLastDataDate();
        }
    }, [isDelisted]);

    useEffect(() => {
        fetchData();
    }, [symbol, chartType, isDelisted]);

    useEffect(() => {
        if (chartData) {
            fetchMarkersData();
        }
    }, [chartData, chartType]);

    useEffect(() => {
        if (chartType === '10m') {
            
            const intervalId = setInterval(fetchData, 600000);

            return () => clearInterval(intervalId);
        }
        if (chartType === 'D') {
            const intervalId = setInterval(fetchLiveDailyData, 1 * 60 * 1000);
            return () => clearInterval(intervalId);
        }
    }, [chartType]);

    useEffect(() => {
        if(markerData){
            fetchMarkersData();
        }
    }, [selectedPercentile]);




    // useEffect(() => {
    //     if (development) {
    //         setSelectedImpactScore(ImpactScore.values[4].value);
    //         setSelectedPercentile(PercentileMoves.values[4].value);
    //     }
    //     else{
    //         if (markerData &&
    //             (chartMinMax.min !== 0 && chartMinMax.min !== undefined) &&
    //             (chartMinMax.max !== 0 && chartMinMax.max !== undefined)
    //         ) {
    //             const filteredData = markerData.filtered.filter((card) => card.ts >= chartMinMax.min && card.ts <= chartMinMax.max);
    
    //             if (filteredData.length > 0){
    //                 return;
    //             }
    //             lowerSelectedValues();
    //         }
    //     }
    // }, [chartMinMax, markerData]);

    // const filterData = (data) => {
    //     const percentile = JSON.parse(selectedPercentile);

    //     const filterMin = percentile[0] / 100;
    //     const filterMax = percentile[1] / 100;

    //     if(development){
    //         const filteredData = data.filter((item) => (item.performance_stats.date_return_oc_percentile <= filterMin ||
    //             item.performance_stats.date_return_oc_percentile >= filterMax)
    //         );  

    //         return filteredData;
    //     }

    //     const filteredData = data.filter((item) => (
    //         item.performance_stats.date_return_oc_percentile <= filterMin || 
    //         item.performance_stats.date_return_oc_percentile >= filterMax
    //     ));   

    //     return filteredData;
    // }

	return (
		<StockChartContainer ref={stockChartWrapperRef} width={width}>
            {
                loading ? 
                    <Loading/>:
                    <>
                    <Container>
                        <TickerInfo>
                            <h1>{tickerData.name}</h1>
                            <h2>
                                {formatSectorAndType(tickerData.gsector, tickerData.type)}
                            </h2>
                        </TickerInfo>
                        <Items>
                            <StockChartToggle chartType={chartType} setChartType={setChartType}/>
                                {assetPage && (
                                    <div style={{width:'200px'}}>
                                        <SearchDropdown
                                            handleSubmit={handleNavigation}
                                            options={assets}
                                            position='right'
                                        />
                                    </div>      
                                )}  
                                <div style={{width:'135px'}}>
                                    <DropdownMenu
                                        label={PercentileMoves.label}
                                        handleChange={handleChangePercentile}
                                        options={PercentileMoves.values}
                                        value={selectedPercentile}
                                    />
                                </div>
                                {/* <div style={{width:'135px'}}>
                                    <Input
                                        min={new Date("15 February, 2023").toISOString().split("T")[0]}
                                        max={new Date().toISOString().split("T")[0]}
                                        onChange={handleDateChange}
                                        style={{ height: '44px' }}
                                        type="date"
                                        value={dateDisplay}
                                    />
                                </div> */}

                                {/* {development && (<div style={{width:'170px'}}>
                                    <DropdownMenu
                                        label={ImpactScore.label}
                                        handleChange={handleChangeImpactScore}
                                        options={ImpactScore.values}
                                        value={selectedImpactScore}
                                    />
                                </div>)} */}
                         
                        </Items>
                    </Container>
                        <>
                            <StockChart
                                carouselPlotLine={carouselPlotLine}
                                chartData={chartData}
                                height={stockChartWrapperRef.current.offsetHeight}
                                hoverPoint={hoveredPoint}
                                loadMoreData={loadMoreData}
                                markerData={markerData}
                                setChartMinMax={setChartMinMax}
                                setClickedPoint={setClickedPoint}
                                setHoverPoint={setHoverPoint}
                                symbol={symbol.toUpperCase()}
                                width={width}
                                isDelisted={isDelisted}
                                lastDate={lastDate}
                            />

                            {markerData &&
                                <StockChartCarousel
                                    chartMinMax={chartMinMax}
                                    clickedPoint={clickedPoint}
                                    hoveredPoint={hoveredPoint}
                                    markerData={markerData}
                                    setCarouselPlotline={setCarouselPlotline}
                                    setHoverPoint={setHoverPoint}
                                />

                            }
                        </>
                    </>
            }
		</StockChartContainer>
	);
};

export default StockChartWrapper;