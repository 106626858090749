import React, { useContext, useEffect, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { string_to_unicode_variant } from 'string-to-unicode-variant';

import {
    AddIcon,
    BackIcon,
    ChartIcon,
    ClockIcon,
    CopyIcon,
    MoreInfoIcon,
    OpenArrowIcon,
    ScreenerIcon,
    ThumbsDownIcon,
    ThumbsUpIcon,
} from '../../Icons/Icons';
import {
    copyTextToClipboard,
    formatDate,
    formatDateTime,
    formatMarketCap,
    ordinalSuffixOf,
    round,
} from '../../../Controllers/utils';
import {
    BlankSpacer,
    ClickableHeading,
    DataOutputContainer,
    FeedButton,
} from '../../StyledComponents/AppStyledComponents';
import { CFDs } from '../../constants';
import {
    CTAButton,
    ExpandedBox,
} from '../../StyledComponents/AppStyledComponents';
import {
    Description,
    DisplayInlineHalfLT,
    DisplayInlineHalfRT,
    ExplanationRow,
    ExplanationTag,
    FlexInteractionRow,
    FullText,
    IconBox,
    IconContainer,
    MetricRow,
    SparklineRow,
} from '../../StyledComponents/StockCardStyledComponents';
import Loading from '../LoadingSpinner';
import ModelSummaryAccordion from './components/ModelSummaryAccordion';
import MoveClassificationView from '../StockCard/components/MoveClassifications';
import SparkLine from '../SparkLine';
import { StateContext } from '../../../Controllers/context/state';
import StockInfo from '../StockInfo';
import supabase from '../../../Controllers/utils/supabaseClient';
import Tooltip from '../Tooltip';
import useOnScreen from '../../../Controllers/hooks/onScreenHook';
import { AuthContext } from '../../../Controllers/context/auth';

const ExpandedView = ({ assetPage,data,isDelisted, showInfo }) => {
    const [cardData, setCardData] = useState({});
    const [expandDescription, setExpandDescription] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const [isCopied, setIsCopied] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);
    const {
        chartTimestamp,
        chartType,
        expandedViewTicker,
        logError,
        setChartTicker,
        setChartTimeStamp,
        setEtfConstituentsTemp,
        setExpandedViewTicker,
        setFeedbackData,
        setMonthlySummaryTimestamp,
        setSectorOptions,
        setShowChart,
        setShowCorrelatedAssetsPopup,
        setShowErrorWindow,
        setShowEtfConstituentsPopup,
        setShowExpandedView,
        setShowFeed,
        setShowFeedbackModal,
        setShowIndustryPeers,
        setShowMonthlySummary,
        setShowWatchlistPopup,
        setSummariesReviewed,
        setSummaryInReview,
        showExpandedView,
        sort,
        summariesReviewed,
    } = useContext(StateContext);

    const [loading, setLoading] = useState(true);
    const [loadingMonthly, setLoadingMonthly] = useState(true);
    const [monthlySummaryExists, setMonthlySummaryExists] = useState(false);
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [percentileMove, setpercentileMove] = useState('');
    const requestedTicker = expandedViewTicker;

    const { user } = useContext(AuthContext);

    let moveColor;
    let plusSign = '';

    if (round(cardData?.date_return_oc * 100, 1) > 0) {
        plusSign = '+';
        moveColor = 'MRGREEN';
    } else if (round(cardData?.date_return_oc * 100, 1) < 0) {
        moveColor = 'MRRED';
    } else if (round(cardData?.date_return_oc * 100, 1) === 0) {
        moveColor = 'MRLITE80';
    }

    const closeExpandedView = () => {
        setShowExpandedView(false);
        setShowFeed(true);
    };

    const fetchData = async ({ retry = true }) => {
        let data;
        if ((chartTimestamp && showInfo)|| (chartTimestamp && isDelisted)) { 
            const res = await supabase.rpc('get_expanded_summary_for_chart', {
                _symbol: requestedTicker,
                _aggr: chartTimestamp.aggr,
                _epoch: chartTimestamp.timestamp,
                _corrasset: sort.correlationAsset,
            });
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
                    fetchData({ retry: false });

                    return;
                }

                if (res?.error?.message !== 'FetchError: Failed to fetch') {
                    setShowErrorWindow(true);
                }

                return;
            }
            if (res?.data?.length) {
                data = res?.data?.[0]?.data_row;
            }
        } else {
            const res = await supabase.rpc('card_data', {
                _symbol: requestedTicker,
                _corr_symbol: sort.correlationAsset || 'SPY',
            });

            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
                    fetchData({ retry: false });

                    return;
                }

                if (res?.error?.message !== 'FetchError: Failed to fetch') {
                    setShowErrorWindow(true);
                }

                return;
            }

            if (res?.data?.length) {
                data = res.data[0];
            }
            else{
             return;
            }
        }

        setCardData(data);
        setLoading(false);

        const percentile = round(data?.date_return_oc_percentile * 100, 2);

        if (percentile >= 97) {
            setpercentileMove('ALPH');
        } else if (percentile >= 93) {
            setpercentileMove('BETA');
        } else if (percentile >= 84) {
            setpercentileMove('GAMM');
        } else if (percentile >= 68) {
            setpercentileMove('DELT');
        } else if (percentile >= 34) {
            setpercentileMove('EPIS');
        } else if (percentile >= 16) {
            setpercentileMove('IOTA');
        } else if (percentile >= 7) {
            setpercentileMove('KAPP');
        } else if (percentile >= 3) {
            setpercentileMove('LAMB');
        } else if (percentile >= 0) {
            setpercentileMove('ZETA');
        }
    };

    useEffect(() => {
        setLoading(true);
        setLoadingMonthly(true);
        setMonthlySummaryExists(false);

        if (showExpandedView && !requestedTicker) {
            closeExpandedView();
        }

        if (data) {
            setCardData(data);
            loadMonthlySummary({ retry: true });
            setLoading(false);
            const percentile = round(data?.date_return_oc_percentile * 100, 2);

            if (percentile >= 97) {
                setpercentileMove('ALPH');
            } else if (percentile >= 93) {
                setpercentileMove('BETA');
            } else if (percentile >= 84) {
                setpercentileMove('GAMM');
            } else if (percentile >= 68) {
                setpercentileMove('DELT');
            } else if (percentile >= 34) {
                setpercentileMove('EPIS');
            } else if (percentile >= 16) {
                setpercentileMove('IOTA');
            } else if (percentile >= 7) {
                setpercentileMove('KAPP');
            } else if (percentile >= 3) {
                setpercentileMove('LAMB');
            } else if (percentile >= 0) {
                setpercentileMove('ZETA');
            }
                return;
        }

        if (isVisible) {
            fetchData({ retry: true });
            loadMonthlySummary({ retry: true });
        }
    }, [chartTimestamp, isVisible, data, requestedTicker, sort.correlationAsset]);

    useEffect(() => {
        let interval;
        if (isVisible && !data) {
            interval = setInterval(() => fetchData({ retry: true }), 60000);

            if (interval !== intervalId) {
                clearInterval(intervalId);
            }

            setIntervalId(interval);
        } else {
            clearInterval(interval);
            setChartTimeStamp(null);
        }

        return () => {
            clearInterval(interval);
        };
    }, [chartTimestamp, isVisible, requestedTicker]);

    const loadMonthlySummary = ({ retry = true }) => {
        supabase.rpc('get_long_term_summary', {
            ticker: expandedViewTicker,
            freq: '5W',
        }).then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry) {
                    loadMonthlySummary({ retry: false });

                    return;
                }

                setMonthlySummaryExists(false);
                setLoadingMonthly(false);

                return;
            }

            if (res?.data?.[0]?.data_row?.summary) {
                setMonthlySummaryExists(true);
                setLoadingMonthly(false);

                return;
            }

            setMonthlySummaryExists(false);
            setLoadingMonthly(false);

            return;
        });
    };

    useEffect(() => {
        setExpandDescription(false);
    }, [requestedTicker]);

    const handleAdd = (e) => {
        setShowWatchlistPopup(prevState => !prevState);
    };

    const handleCopyClick = () => {
        const text = `${cardData.name} (${cardData.symbol}) [${plusSign}${round(cardData?.date_return_oc * 100,1)}%, ${formatMarketCap(cardData?.date_return_oc * cardData?.mcap_mm)}]\n${(cardData?.gsector, cardData?.gsubind)}\n${cardData?.dtm_summary?.dtm_summary} \n—${string_to_unicode_variant(`Source: MarketReader Inc @ ${formatDateTime(cardData?.dtm_summary?.time_utc)}`,'italic')}`;

        copyTextToClipboard(text).then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        });
    };

    const handleExpandDescription = () => {
        setExpandDescription(prevState => !prevState);
    };

    const handleFeedback = (e, rating) => {
        e.stopPropagation();
        setSummariesReviewed(prevState => ({
            ...prevState,
            [cardData?.dtm_summary?.dtm_summary_meta?.trigger_id]: rating ? 'up' : 'down',
        }));
        setSummaryInReview(cardData?.dtm_summary?.dtm_summary)

        supabase.from('user_feedback').insert([{
            user_id: user?.id,
            trigger_id: cardData?.dtm_summary?.dtm_summary_meta?.trigger_id,
            positive: rating,
        }]).select().then(res => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                return;
            }

            if (!rating) {
                const data = res?.data[0];
                setFeedbackData(data);
                setShowFeedbackModal(true);
            }
        });
    };

    const sparklineContainerRef = useRef();

    const handleOpenChart = () => {
        setChartTicker(cardData?.symbol);
        setShowChart(true);
    };
    const handleOpenMonthlySummary = () => {
        setMonthlySummaryTimestamp(chartTimestamp?.timestamp * 1000);
        setShowMonthlySummary(true);
    };

    const handleViewCorrelatedAssets = () => {
        setExpandedViewTicker(cardData?.symbol);
        setShowCorrelatedAssetsPopup(true);
    };

    const handleViewEtfConstituents = () => {
        setEtfConstituentsTemp({
            constituents: cardData?.meta_data?.ETF?.constituents,
            name: cardData?.name,
            symbol: cardData?.symbol,
        });
        setExpandedViewTicker(cardData?.symbol);
        setShowEtfConstituentsPopup(true);
    };

    const handleViewIndustryPeers = () => {
        const sectorOptions = `gsector=${"'" + cardData?.gsector + "'"}${cardData?.gsubind ? ' AND ' + 'gsubind=' + "'" + cardData?.gsubind + "'" : ''}`;
        setSectorOptions(sectorOptions);
        setExpandedViewTicker(cardData?.symbol);
        setShowIndustryPeers(true);
    };

    let utcPlusTen = new Date(cardData?.time_utc);
    utcPlusTen.setMinutes(utcPlusTen.getMinutes() + 10);

    return (
        <div
            className='overlay'
            style={{ height: '100%', weigh: '100%' }}
            ref={ref}
        >
            {loading ? (
                <Loading />
            ) : (
                <DataOutputContainer>
                    <ClickableHeading>
                        {/* {chartTimestamp && 
                            <BackIcon
                                onClick={() => setChartTimeStamp(null)}
                                style={{ position: 'absolute', right: '10px', top: '10px' }}
                            />
                        } */}
                        <div
                            onClick={assetPage ? null : handleOpenChart}
                            style={{ cursor: !assetPage && 'pointer' }}
                        >
                            <div style={{ display: 'flex' }}>
                                {!CFDs.includes(cardData?.type) && 
                                    <ClockIcon style={{ marginRight: 5, marginTop: 7 }} />
                                }
                                <h1>
                                    {cardData?.name}
                                </h1>
                            </div>
                            {CFDs.includes(cardData?.type) ? (
                                cardData?.type === 'currency_fiat' ? 
                                    <h2>FX</h2> :
                                    null) : 
                                <h2>
                                    {cardData?.gsector},&nbsp;
                                    {cardData?.gsubind},&nbsp;
                                    {cardData?.type === 'ETP' ? 'ETF' : cardData?.type}
                                </h2>
                            }
                        </div>
                        <BlankSpacer />
                        <FeedButton
                            onClick={handleExpandDescription}
                            open={expandDescription}
                            style={{ cursor: 'pointer', padding: '10px 5px' }}
                        >
                            Asset Description
                            <OpenArrowIcon
                                style={{ marginLeft: 5 }}
                                open={expandDescription}
                            />
                        </FeedButton>
                        <Description expanded={expandDescription}>
                            {cardData?.meta_data?.description}
                        </Description>
                        <BlankSpacer />
                        <div
                            onClick={assetPage ? null : handleOpenChart}
                            style={{ cursor: !assetPage && 'pointer' }}
                        >
                            <StockInfo data={cardData} percentileMove={percentileMove} />
                            <BlankSpacer />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p>
                                    {cardData?.correlation_symbol} Correlation:{" "}
                                    <b>{round(cardData?.correlation, 2)}</b>
                                </p>
                                <p>
                                    Beta to {cardData?.correlation_symbol}:{" "}
                                    <b>{round(cardData?.beta, 2)}</b>
                                </p>
                            </div>
                        </div>
                    </ClickableHeading>
                    <ExpandedBox>
                        <p className='center'>
                            {chartTimestamp?.aggr === 'dtm' ?
                                formatDate(cardData?.time_utc) :
                                chartTimestamp?.aggr === '10min' ?
                                `${formatDateTime(cardData?.time_utc)} - ${formatDateTime(utcPlusTen)}` :
                                `${formatDateTime(cardData?.date_time_utc)} - ${formatDateTime(utcPlusTen)}`
                            }
                        </p>
                        <MoveClassificationView
                            macro={cardData?.move_classification?.dtm_macro}
                            sector={cardData?.move_classification?.dtm_sector}
                            micro={cardData?.move_classification?.dtm_micro}
                            percentile={cardData?.date_return_oc_percentile}
                            type={cardData?.type}
                        />
                        <BlankSpacer />
                        <ExplanationRow>
                            {cardData?.dtm_tags ? [...new Set(cardData?.dtm_tags)]?.filter((tag) => tag !== 'Macro Move' && tag !== 'Sector Move').map((tag) => (
                                        <ExplanationTag key={tag}>
                                            {tag}
                                        </ExplanationTag>
                                    ))
                                : [...new Set(cardData?.tags)]?.filter((tag) => tag !== 'Macro Move' && tag !== 'Sector Move').map((tag) => (
                                        <ExplanationTag key={tag}>
                                            {tag}
                                        </ExplanationTag>
                            ))}
                        </ExplanationRow>
                        <BlankSpacer />
                        {(!chartTimestamp || chartTimestamp?.aggr === 'dtm') && cardData?.dtm_summary ?
                            <FullText>
                                <p>
                                    {cardData?.dtm_summary?.dtm_summary?.split(/\n\n|\n/).map((line) => (
                                        <>
                                            {line}
                                            <br />
                                        </>
                                    ))}
                                </p>
                            </FullText> : 
                            <FullText>
                                    {data || chartTimestamp ?
                                        (cardData.summary?.[0].aggr === "10min" ? 
                                            <p>{cardData.summary?.[0]?.summary}</p> : 
                                            <p>{cardData.summary?.[0]?.dtm_summary}</p>
                                        )
                                    :
                                    <p>
                                        {cardData?.summary?.[0]?.summary?.split(/\n\n|\n/).map((line) => (
                                            <>
                                                {line}
                                                <br />
                                            </>
                                        ))}
                                    </p>
                                    }
                            </FullText>
                            }
                            {cardData?.dtm_summary?.time_utc &&
                                <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    Published: {formatDateTime(cardData?.dtm_summary?.time_utc)}
                                        <IconContainer>
                                            <div style={{ width: '50px', display: 'flex', justifyContent: 'center' }}>
                                                <ThumbsUpIcon selected={summariesReviewed[cardData?.dtm_summary?.dtm_summary_meta?.trigger_id] === 'up'} onClick={(e) => handleFeedback(e, true)}/>
                                            </div>
                                            <div style={{ width: '50px', display: 'flex', justifyContent: 'center' }}>
                                                <ThumbsDownIcon selected={summariesReviewed[cardData?.dtm_summary?.dtm_summary_meta?.trigger_id] === 'down'} onClick={(e) => handleFeedback(e, false)}/>
                                            </div>
                                        </IconContainer>
                                </p>
                            }
                            <BlankSpacer />
                            {cardData?.dtm_summary?.dtm_summary_meta?.information_score?.label &&
                                <p>
                                    <span style={{ margin: 3 }}>
                                        <Tooltip
                                            position='right'
                                            text="This measurement reflects the level of information provided in MarketReader's explanation.  These levels allow the user to filter out statements containing information of lesser impact."
                                            tooltipWidth={240}
                                        >
                                            <MoreInfoIcon />
                                        </Tooltip>
                                    </span>
                                    {/* &nbsp; */}
                                    Explanation Information Score: {cardData?.dtm_summary?.dtm_summary_meta?.information_score?.label}
                                </p>
                            }
                            <BlankSpacer />
                            <MetricRow>
                                <DisplayInlineHalfLT percentileMove={percentileMove} space='40%'>
                                    {ordinalSuffixOf(round(cardData?.date_return_oc_percentile * 100, 0))}
                                    <br />
                                    Percentile
                                </DisplayInlineHalfLT>
                                <DisplayInlineHalfRT moveColor={moveColor} space='28%' >
                                    Vol Adj Return <br /> {round(cardData?.date_return_oc_voladj)}σ &nbsp;
                                </DisplayInlineHalfRT>
                                <FlexInteractionRow>
                                    <IconBox cardDimensions={{ state: 1}} onClick={handleAdd}>
                                        <Tooltip position={'left'} text='Add to Watchlist'>
                                            <AddIcon />
                                        </Tooltip>
                                    </IconBox> &nbsp;
                                    <IconBox cardDimensions={{ state: 1}} onClick={handleCopyClick}>
                                        <Tooltip position={'left'} text='Copy to Clipboard'>
                                            <CopyIcon selected={isCopied} />
                                        </Tooltip>
                                    </IconBox>
                                </FlexInteractionRow>
                            </MetricRow>
                            <BlankSpacer />
                        {cardData?.type !== 'ETP' && loadingMonthly ?
                                <Loading dimensions={{ height: '40px', width: '40px' }} /> :
                                monthlySummaryExists &&
                                    <CTAButton onClick={handleOpenMonthlySummary}>
                                        <h2>
                                            View Monthly Summary
                                        </h2>
                                    </CTAButton>
                        }    
                    </ExpandedBox>
                    <ExpandedBox>
                        <BlankSpacer />
                        {cardData?.spark_data && (
                            <SparklineRow ref={sparklineContainerRef}>
                                <SparkLine
                                    expanded
                                    series={cardData.spark_data}
                                    sparklineContainerRef={sparklineContainerRef}
                                />
                            </SparklineRow> 
                        )}
                        <BlankSpacer />
                            {(!isMobile || isTablet) && !assetPage && !showInfo &&
                                <CTAButton onClick={handleOpenChart}>
                                <h2>
                                View Chart
                                <ChartIcon />
                                </h2>
                                </CTAButton>
                            }
                        
                        {cardData?.type !== 'ETP' &&
                            <CTAButton onClick={handleViewIndustryPeers}>
                                <h2>
                                    View Industry Peers
                                    <ScreenerIcon />
                                </h2>
                            </CTAButton>
                        }
                        {cardData?.meta_data?.ETF?.constituents?.length &&
                            <CTAButton onClick={handleViewEtfConstituents}>
                                <h2>
                                    View ETF Constituents
                                    <ScreenerIcon />
                                </h2>
                            </CTAButton>
                        }
                        <CTAButton onClick={handleViewCorrelatedAssets}>
                            <h2>
                                View Correlated Assets
                                <ScreenerIcon />
                            </h2>
                        </CTAButton>
                    </ExpandedBox>
                    <ModelSummaryAccordion
                        modelData={cardData?.dtm_model_outputs || cardData?.model_outputs}
                    />
                </DataOutputContainer>
            )}
        </div>
    );
};

export default ExpandedView;
