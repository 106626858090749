import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AlertFeed from '../../Global/Views/FeedViewer/AlertFeed';
import { AuthContext } from '../../Controllers/context/auth';
import {
	BlankSpacer,
	ViewModule,
} from '../../Global/StyledComponents/AppStyledComponents';
import Chart from './components/Chart';
import Drawer from '../../Global/Components/Drawer';
import ExpandedView from '../../Global/Components/ExpandedView/ExpandedView';
import FeedViewer from '../../Global/Views/FeedViewer/FeedViewer';
import {
	Input,
	SearchArea,
} from '../../Global/StyledComponents/UserFormStyledComponents';
import Loading from '../../Global/Components/LoadingSpinner';
import MacroSummariesFeed from '../../Global/Views/FeedViewer/MacroSummariesFeed';
import MacroMovers from '../../Global/Views/FeedViewer/MacroMovers';
import SearchDropdown from '../../Global/Components/SearchDropdown';
import { StateContext } from '../../Controllers/context/state';
import { useParams } from 'react-router-dom';
import StockChartWrapper from '../../Global/Components/StockChartWrapper/StockChartWrapper';
import TickerNotFound from '../../Global/Components/TickerNotFound';
import { getCurrentDate } from '../../Controllers/utils';
import supabase from '../../Controllers/utils/supabaseClient';
const AssetMonitor = () => {

	const [lastDate, setLastDate] = useState(null);
    const [dateDisplay, setDateDisplay] = useState(new Date().toISOString().split("T")[0]);
    const [doesExist, setDoesExist] = useState(true);
    const [isDelisted, setIsDelisted] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date().getTime() / 1000);
    const [startingWidth, setStartingWidth] = useState();
    const params = useParams();
	
	const starSegments = params['*'] ? params['*'].split('/') : [];
	const lastSegment = starSegments[starSegments.length - 1];
	const isDate = /^\d{4}-\d{2}-\d{2}$/.test(lastSegment);
	const symbolParts = isDate ? starSegments.slice(0, -1) : starSegments;
	const symbol = params.symbol + (symbolParts.length > 0 ? `/${symbolParts.join('/')}` : '');
	// const date = isDate ? new Date(lastSegment) : getCurrentDate();
    
	const {
		assets,
        activeAssets,
        development,
		expandedViewTicker,
		fullNameKey,
		isLoading,
		logError,
		newAlerts,
		setExpandedViewTicker,
	} = useContext(StateContext);
	const navigate = useNavigate();
	const { session } = useContext(AuthContext);
	const [width, setWidth] = useState();

	const handleDateChange = (e) => {
		const newDate = e.target.value;
		setSelectedDate(new Date(newDate).getTime() / 1000);
		setDateDisplay(newDate);

		// Update the URL with the new date
		const newUrl = `/ticker/${symbol}/${newDate}`;
		navigate(newUrl, { replace: true });
	};

	const handleSearchSubmit = (value) => {
		navigate(`/ticker/${value}`);
	};

	useEffect(() => {
		setWidth(window.innerWidth - 554);
		setStartingWidth(window.innerWidth - 554);
	}, [symbol]);

	useEffect(() => {
		setExpandedViewTicker(symbol);
	}, [symbol]);

	useEffect(() => {
		const checkAssetExistence = async () => {
			const assetExists = activeAssets.some(asset => asset.symbol === symbol);
			if (assetExists) {
				setDoesExist(true);
				setIsDelisted(false);

			} else {
                const assetExists = assets.some(asset => asset.symbol === symbol);
				setDoesExist(assetExists);
				setIsDelisted(assetExists);
			}
		};

		checkAssetExistence();
	}, [symbol, assets, activeAssets]);

	if (isLoading) {
		return (
			<ViewModule>
				<Loading />
			</ViewModule>
		);
	}

	return (
		<>
			<ViewModule>
                {doesExist ? (
				<div style={{ display: 'flex', height: '100%' }}>
					<div style={{ marginRight: '10px' }}>
						<StockChartWrapper
                            assetPage
                            assets={assets}
                            dateDisplay={dateDisplay}
                            handleDateChange={handleDateChange}
                            handleNavigation={handleSearchSubmit}
                            isDelisted={isDelisted}
                            lastDate={lastDate}
                            setLastDate={setLastDate}
                            symbol={symbol}
                            width={width}
						/>
					</div>
				    <div style={{ height: '100%', width: '400px' }}>
						<ExpandedView assetPage isDelisted={isDelisted} />
				    </div>
				</div>
                ) : (
                    <TickerNotFound  text={'Invalid asset or not currently covered by MarketReader. Please check your spelling and try again.'}  handleNavigation={handleSearchSubmit} />
                )}
			</ViewModule>
			
			<Drawer rotate={'true'} setWidth={setWidth} startingWidth={startingWidth}>
				{/* <ExpandedView title={`${expandedViewTicker} Summary`} /> */}
				<FeedViewer title='News Feeds' />
				{development && <MacroMovers title='Macro Movers' />}
				{/* <AlertFeed id='alerts' number={newAlerts} title='Watchlist Alerts' /> */}
				<MacroSummariesFeed title='Macro Overview' />
			</Drawer>
		</>
	);
};

export default AssetMonitor;
