import { useContext, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';


import CountdownProgressBar from './CountdownProgressBar';
import { NavyGreenBar } from "../StyledComponents/Graphics";
import { NavivationItem } from './NavigationMenu/StyledComponents';
import NavigationMenu from './NavigationMenu/NavigationMenu';
import { StateContext } from '../../Controllers/context/state';
import { TrackingModuleContainer } from '../StyledComponents/AppStyledComponents';
import { WhiteTextGlassLogo } from "../Logos/Logos";
import supabase from '../../Controllers/utils/supabaseClient';
import { isMobile, isTablet } from 'react-device-detect';
import { SubmitButton } from '../StyledComponents/UserFormStyledComponents';
import { AuthContext } from '../../Controllers/context/auth';

const TrackingModule = () => {
    const {
        customerProfile,
        setShowManageSubscriptionPopUp,
    } = useContext(StateContext);
    const { user } = useContext(AuthContext);
    const { pathname } = useLocation();
    const PAGES = {
        '': 'Dashboard',
        'admin': 'Admin',
        'etf': 'ETF Monitor',
        'faqs': 'Knowledge and Support',
        'macro': 'Macro Monitor - Beta',
        'news': 'Market-Wide News',
        'unusualMoves': 'Unusual Moves',
        'screener': 'Smart Screener',
        'watchlists': 'Watchlists',
    };

    useEffect(() => {
        supabase.from('profiles').select('manage_subscription').then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                return;
            }
            setShowManageSubscriptionPopUp(res?.data[0]?.manage_subscription && (!isMobile || isTablet));
        });
    }, []);

    const manageSubscription = async () => {
        await supabase.functions.invoke('customerPortal', {
            body: {
                userEmail: user?.email,
            },
        }).then(res => {
            const { session } = JSON.parse(res.data);
            window.open(session.url, '_blank');
        });
    };

    return (
        <TrackingModuleContainer>
            <NavyGreenBar >
                <NavLink to='/' style={{ marginRight: '10px' }}>
                    <WhiteTextGlassLogo title="Market Reader logo" />
                </NavLink>
                <NavivationItem style={{ minWidth: '345px', width: '25%' }}>
                    {customerProfile?.subscriptions?.[0]?.status === 'trialing' &&
                        <>
                            <CountdownProgressBar
                                startEpoch={customerProfile?.subscriptions?.[0]?.trial_start}
                                endEpoch={customerProfile?.subscriptions?.[0]?.trial_end}
                                label="Free Trial"
                            />
                            <SubmitButton
                                onClick={manageSubscription}
                                style={{ fontSize: '13px', lineHeight: '14px', margin: '0 10px', width: 'fit-content' }}
                                >
                                Manage Subscription
                            </SubmitButton>
                        </>
                    }
                </NavivationItem>
                <NavivationItem style={{ color: ' white', width: 'fit-content', whiteSpace: 'nowrap' }}>
                    {PAGES[pathname.split('/')[1]]}
                </NavivationItem>
                <NavigationMenu />
            </NavyGreenBar>
        </TrackingModuleContainer>

    );
};

export default TrackingModule;